import {isEmpty} from 'ramda';

export function mergeBasketItems<
  T extends {id: string},
  // TODO: Remove optional in T20-62417
  U extends {id: string; isSelected?: boolean},
>(newBasketItems: T[], mappedBasketItems: U[]): U[] {
  if (isEmpty(newBasketItems)) {
    return [];
  }

  const mergedBasketItems = newBasketItems.map((newBasketItem) => {
    const existingBasketItem = mappedBasketItems.find(
      (existingBasketItem) => existingBasketItem.id === newBasketItem.id
    );

    return existingBasketItem
      ? {...existingBasketItem, ...newBasketItem}
      : {...newBasketItem, isSelected: false};
  });

  return mergedBasketItems as U[];
}
