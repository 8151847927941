import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  LabelActions,
  openDropdown,
  OptionTypeBase,
  showNotification,
} from 'platform/components';
import {Box, Show, Spinner, VStack} from 'platform/foundation';
import {object, array, boolean} from 'yup';

import {useCallback} from 'react';

import {concat, head, isNil} from 'ramda';

import {
  SendEmailRequestBody,
  useGetContextTemplateListQuery,
  useGetCustomerV2Query,
  useRenderCustomerEmailTemplateMutation,
  useSendEmailMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {buildArray, buildObject, Nullish, suffixTestId, TestIdProps, yupString} from 'shared';

import {getCustomerContacts} from '../../utils/getCustomerContacts';
import {handleApiError} from '../../utils/handleApiError';
import {DocumentUpload} from '../SendEmail/DocumentUpload';
import {DocumentItem} from '../SendEmail/types/DocumentItem';
import {TemplatesEmailDropdownContent} from './components/TemplatesEmailDropdownContent';
import {SendEmailFormValues} from './types/SendEmailFormValues';

interface SendEmailProps extends TestIdProps {
  customerId?: string | Nullish;
  documents?: DocumentItem[];
  shouldDisplayTemplateChoice?: boolean;
}

export function SendEmail(props: SendEmailProps) {
  const {
    data,
    isLoading: isLoadingCustomer,
    isError,
  } = useGetCustomerV2Query(
    {customerId: props.customerId ?? ''},
    {
      skip: isNil(props.customerId),
      selectFromResult: (res) => ({
        ...res,
        data: getCustomerContacts(res.data),
      }),
    }
  );

  const [sendEmail, {isLoading}] = useSendEmailMutation();
  const [renderTemplate, {isLoading: isRenderTemplateInProgress}] =
    useRenderCustomerEmailTemplateMutation();
  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError: isTemplatesError,
  } = useGetContextTemplateListQuery({
    type: 'email',
  });

  const onSubmit: FormSubmitHandler<SendEmailFormValues> = async ({
    to,
    body,
    subject,
    documents,
    sendAsPdf,
  }) => {
    const attachmentFiles = documents?.map((d: DocumentItem) => ({
      fileId: d.fileId,
      sendAsPdf,
    }));
    const args: SendEmailRequestBody = {
      to,
      body,
      subject,
      attachmentFiles,
    };
    await sendEmail(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.emailSendSuccessfully'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const getEmailOptions = useCallback(
    (creatableOptions?: string[]): OptionTypeBase<string>[] => {
      if (!data?.length && !creatableOptions?.length) {
        return [];
      }

      const personsEmails = data?.flatMap((p) =>
        p.emails.map((e) => ({
          value: e.email,
          label: e.email,
        }))
      );

      const createdEmails = creatableOptions?.map((email) => ({
        value: email,
        label: email,
      }));

      return concat(personsEmails ?? [], createdEmails ?? []);
    },
    [data]
  );

  const defaultValues = buildObject<{
    documents: DocumentItem[] | undefined;
    to: string[];
    sendAsPdf: boolean;
  }>()
    .documents(props.documents)
    .to([head(getEmailOptions())?.value ?? ''], getEmailOptions()?.length === 1)
    .sendAsPdf(true)
    .build();

  return (
    <DataStatus
      isLoading={isLoadingCustomer || isLoadingTemplates}
      isError={isError || isTemplatesError}
    >
      <Form<SendEmailFormValues> onSubmit={onSubmit} defaultValues={defaultValues} schema={schema}>
        {(control, formApi) => {
          const currentRecipients = formApi.watch('to');
          return (
            <VStack spacing={4}>
              <FormField
                control={control}
                name="to"
                type="creatableMultiChoice"
                isRequired
                placeholder={i18n.t('general.labels.select')}
                formatCreateLabel={(inputValue: string) =>
                  `${i18n.t('general.actions.add')} ${inputValue}`
                }
                options={getEmailOptions(currentRecipients)}
                label={i18n.t('general.labels.email')}
                data-testid={suffixTestId('recipient', props)}
                isNotClearable
              />
              <Box position="relative">
                <VStack spacing={4}>
                  <Show when={isRenderTemplateInProgress && props.shouldDisplayTemplateChoice}>
                    <Spinner variant="overlay" />
                  </Show>
                  <FormField
                    control={control}
                    name="subject"
                    type="text"
                    label={i18n.t('general.labels.subject')}
                    isRequired
                    data-testid={suffixTestId('subject', props)}
                    labelActions={buildArray<LabelActions[number]>().when(
                      props.shouldDisplayTemplateChoice,
                      {
                        leftIcon: 'content/add_circle',
                        title: i18n.t('general.labels.templates'),
                        onClick: (e) =>
                          openDropdown(
                            e,
                            <TemplatesEmailDropdownContent
                              formApi={formApi}
                              control={control}
                              renderTemplate={renderTemplate}
                              templates={templates}
                              customerId={props.customerId}
                            />,
                            {
                              isHeightLimited: true,
                            }
                          ),
                      }
                    )}
                  />
                  <FormField
                    control={control}
                    name="body"
                    type="textarea"
                    label={i18n.t('general.labels.message')}
                    data-testid={suffixTestId('message', props)}
                    minRows={3}
                    isRequired
                  />
                </VStack>
              </Box>
              <FormField
                control={control}
                type="checkbox"
                name="sendAsPdf"
                data-testid={suffixTestId('sendAsPdf', props)}
                label={i18n.t('general.actions.sendAsPdf')}
              />
              <DocumentUpload control={control} data-testid={props['data-testid']} />
              <ButtonGroup align="right">
                <Button
                  variant="secondary"
                  onClick={closeCurrentDialog}
                  isDisabled={isLoading}
                  title={i18n.t('general.actions.discard')}
                  data-testid={suffixTestId('discard', props)}
                />
                <FormButton
                  type="submit"
                  isLoading={isLoading}
                  control={control}
                  title={i18n.t('general.actions.send')}
                  data-testid={suffixTestId('send', props)}
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}
const schema = object({
  to: array().of(yupString).required().min(1),
  subject: yupString.required().max(160),
  body: yupString.required(),
  sendAsPdf: boolean(),
});
