import {z} from 'zod';

const PriceTypeSchema = z
  .enum([
    'SVCPRICETYPE_TIME_NORM',
    'SVCPRICETYPE_CALCULATION_PRICE',
    'SVCPRICETYPE_DIRECT_PRICE',
    'SVCPRICETYPE_COOPERATION',
  ])
  .nullable();

const LabourBasketItemSchema = z.object({
  basketItemId: z.string().nullable().optional(),
  labourCatalogId: z.string().nullable().optional(),
  priceType: PriceTypeSchema.optional(),
  name: z.string().nullable().optional(),
  number: z.string().nullable().optional(),
  workType: z.string().nullable().optional(),
  quantity: z.number().nullable().optional(),
  unit: z.string().nullable().optional(),
  isUnitPriceWithVat: z.boolean().nullable().optional(),
  basePriceSource: z.string().nullable().optional(),
  basePriceType: z.string().nullable().optional(),
  isPriceUnitManual: z.boolean().nullable().optional(),
  purchasePriceUnitWithoutVat: z.number().nullable().optional(),
  purchasePriceUnitVat: z.number().nullable().optional(),
  purchasePriceUnitWithVat: z.number().nullable().optional(),
  priceUnitWithoutVat: z.number().nullable().optional(),
  priceUnitVat: z.number().nullable().optional(),
  priceUnitWithVat: z.number().nullable().optional(),
  vatType: z.string().nullable().optional(),
  currency: z.string().nullable().optional(),
  isDoNotApplyDiscount: z.boolean().nullable().optional(),
  isDoNotApplyDiscountEditable: z.boolean().nullable().optional(),
});

export const GetBasketLabourItemLabourApiApiResponseSchema = z
  .object({
    labourBasketItem: LabourBasketItemSchema.nullable().optional(),
  })
  .nullable();

export type GetBasketLabourItemLabourApiApiResponse = z.infer<
  typeof GetBasketLabourItemLabourApiApiResponseSchema
>;

export const GetBasketLabourItemLabourApiApiArgSchema = z.object({basketItemId: z.string()});
export type GetBasketLabourItemLabourApiApiArg = z.infer<
  typeof GetBasketLabourItemLabourApiApiArgSchema
>;

const WarehouseSchema = z.object({
  warehouseId: z.string().nullable().optional(),
  warehouseArticleId: z.string().nullable().optional(),
  storageLocation: z.string().optional(),
  discountGroup: z.string().optional(),
});

const MaterialBasketItemSchema = z.object({
  basketItemId: z.string().nullable().optional(),
  warehouse: WarehouseSchema.nullable().optional(),
  priceType: PriceTypeSchema.optional(),
  name: z.string().nullable().optional(),
  number: z.string().nullable().optional(),
  workType: z.string().nullable().optional(),
  quantity: z.number().nullable().optional(),
  unit: z.string().nullable().optional(),
  isUnitPriceWithVat: z.boolean().nullable().optional(),
  basePriceSource: z.string().nullable().optional(),
  basePriceType: z.string().nullable().optional(),
  isPriceUnitManual: z.boolean().nullable().optional(),
  purchasePriceUnitWithoutVat: z.number().nullable().optional(),
  purchasePriceUnitVat: z.number().nullable().optional(),
  purchasePriceUnitWithVat: z.number().nullable().optional(),
  priceUnitWithoutVat: z.number().nullable().optional(),
  priceUnitVat: z.number().nullable().optional(),
  priceUnitWithVat: z.number().nullable().optional(),
  vatType: z.string().nullable().optional(),
  currency: z.string().nullable().optional(),
  isDoNotApplyDiscount: z.boolean().nullable().optional(),
  isDoNotApplyDiscountEditable: z.boolean().nullable().optional(),
});

export const GetBasketLabourItemMaterialApiApiResponseSchema = z
  .object({
    materialBasketItem: MaterialBasketItemSchema.nullable().optional(),
  })
  .nullable();

export type GetBasketLabourItemMaterialApiApiResponse = z.infer<
  typeof GetBasketLabourItemMaterialApiApiResponseSchema
>;

export const GetBasketLabourItemMaterialApiApiArgSchema = z.object({basketItemId: z.string()});
export type GetBasketLabourItemMaterialApiApiArg = z.infer<
  typeof GetBasketLabourItemMaterialApiApiArgSchema
>;

export const DeleteBasketItemApiApiResponseSchema = z.void();
export type DeleteBasketItemApiApiResponse = z.infer<typeof DeleteBasketItemApiApiResponseSchema>;

export const DeleteBasketItemApiApiArgSchema = z.object({basketItemId: z.string()});
export type DeleteBasketItemApiApiArg = z.infer<typeof DeleteBasketItemApiApiArgSchema>;

export const PatchBasketItemLabourApiApiResponseSchema = z.void();
export type PatchBasketItemLabourApiApiResponse = z.infer<
  typeof PatchBasketItemLabourApiApiResponseSchema
>;

const PatchBasketItemLabourApiBodySchema = z
  .object({
    name: z.string(),
    number: z.string().nullable(),
    workType: z.string(),
    isPriceUnitManual: z.boolean(),
    vatType: z.string(),
    quantity: z.number(),
    unit: z.string(),
    isUnitPriceWithVat: z.boolean(),
    purchasePricePerUnit: z.number(),
    sellingPricePerUnit: z.number(),
  })
  .nullable();

export const PatchBasketItemLabourApiApiArgSchema = z.object({
  basketItemId: z.string(),
  body: PatchBasketItemLabourApiBodySchema,
});

export type PatchBasketItemLabourApiApiArg = z.infer<typeof PatchBasketItemLabourApiApiArgSchema>;

export const PatchBasketItemMaterialApiApiResponseSchema = z.void();
export type PatchBasketItemMaterialApiApiResponse = z.infer<
  typeof PatchBasketItemMaterialApiApiResponseSchema
>;

const PatchBasketItemMaterialApiBodySchema = z
  .object({
    quantity: z.number(),
    isUnitPriceWithVat: z.boolean(),
    sellingPricePerUnit: z.number(),
    vatType: z.string(),
    isPriceUnitManual: z.boolean(),
  })
  .nullable();

export const PatchBasketItemMaterialApiApiArgSchema = z.object({
  basketItemId: z.string(),
  body: PatchBasketItemMaterialApiBodySchema,
});

export type PatchBasketItemMaterialApiApiArg = z.infer<
  typeof PatchBasketItemMaterialApiApiArgSchema
>;

export const PatchBasketItemQuantityApiApiResponseSchema = z.void();
export type PatchBasketItemQuantityApiApiResponse = z.infer<
  typeof PatchBasketItemQuantityApiApiResponseSchema
>;

const PatchBasketItemQuantityApiBodySchema = z
  .object({
    quantity: z.number(),
  })
  .nullable();

export const PatchBasketItemQuantityApiApiArgSchema = z.object({
  basketItemId: z.string(),
  body: PatchBasketItemQuantityApiBodySchema,
});

export type PatchBasketItemQuantityApiApiArg = z.infer<
  typeof PatchBasketItemQuantityApiApiArgSchema
>;

export const DeleteBasketLabourItemsApiApiResponseSchema = z.void();
export type DeleteBasketLabourItemsApiApiResponse = z.infer<
  typeof DeleteBasketLabourItemsApiApiResponseSchema
>;

export const DeleteBasketLabourItemsApiApiArgSchema = z.object({
  packageId: z.string(),
});

export type DeleteBasketLabourItemsApiApiArg = z.infer<
  typeof DeleteBasketLabourItemsApiApiArgSchema
>;

export const PostBasketLabourItemCheckoutApiApiResponseSchema = z.void();
export type PostBasketLabourItemCheckoutApiApiResponse = z.infer<
  typeof PostBasketLabourItemCheckoutApiApiResponseSchema
>;

export const PostBasketLabourItemCheckoutApiApiArgSchema = z.object({
  packageId: z.string(),
});

export type PostBasketLabourItemCheckoutApiApiArg = z.infer<
  typeof PostBasketLabourItemCheckoutApiApiArgSchema
>;

export const PostBasketLabourItemLabourCatalogApiApiResponseSchema = z
  .object({
    id: z.string().optional(),
  })
  .nullable();

export type PostBasketLabourItemLabourCatalogApiApiResponse = z.infer<
  typeof PostBasketLabourItemLabourCatalogApiApiResponseSchema
>;

const PostBasketLabourItemLabourCatalogApiBodySchema = z
  .object({
    labourCatalogId: z.string(),
  })
  .nullable();

export const PostBasketLabourItemLabourCatalogApiApiArgSchema = z.object({
  packageId: z.string(),
  body: PostBasketLabourItemLabourCatalogApiBodySchema,
});

export type PostBasketLabourItemLabourCatalogApiApiArg = z.infer<
  typeof PostBasketLabourItemLabourCatalogApiApiArgSchema
>;

export const LabourtBasketItemTypeSchema = z.object({
  basketItemId: z.string().optional(),
  labourCatalogId: z.object({}).nullable().optional(),
  priceType: PriceTypeSchema.optional(),
  name: z.string().nullable().optional(),
  number: z.string().nullable().optional(),
  quantity: z.number().nullable().optional(),
  unit: z.string().nullable().optional(),
});

export type LabourtBasketItemType = z.infer<typeof LabourtBasketItemTypeSchema>;

export const GetBasketLabourItemsApiApiResponseSchema = z
  .object({
    labourBasketItem: z.array(LabourtBasketItemTypeSchema).nullable().optional(),
  })
  .nullable();

export type GetBasketLabourItemsApiApiResponse = z.infer<
  typeof GetBasketLabourItemsApiApiResponseSchema
>;

export const GetBasketLabourItemsApiApiArgSchema = z.object({
  packageId: z.string(),
});

export type GetBasketLabourItemsApiApiArg = z.infer<typeof GetBasketLabourItemsApiApiArgSchema>;

export const DeleteBasketMaterialItemsApiApiResponseSchema = z.void();
export type DeleteBasketMaterialItemsApiApiResponse = z.infer<
  typeof DeleteBasketMaterialItemsApiApiResponseSchema
>;

export const DeleteBasketMaterialItemsApiApiArgSchema = z.object({
  packageId: z.string(),
});

export type DeleteBasketMaterialItemsApiApiArg = z.infer<
  typeof DeleteBasketMaterialItemsApiApiArgSchema
>;

export const PostBasketMaterialItemCheckoutApiApiResponseSchema = z.void();
export type PostBasketMaterialItemCheckoutApiApiResponse = z.infer<
  typeof PostBasketMaterialItemCheckoutApiApiResponseSchema
>;

export const PostBasketMaterialItemCheckoutApiApiArgSchema = z.object({
  packageId: z.string(),
});

export type PostBasketMaterialItemCheckoutApiApiArg = z.infer<
  typeof PostBasketMaterialItemCheckoutApiApiArgSchema
>;

export const PostBasketMaterialItemPriceListApiApiResponseSchema = z
  .object({
    id: z.string().optional(),
  })
  .nullable();

export type PostBasketMaterialItemPriceListApiApiResponse = z.infer<
  typeof PostBasketMaterialItemPriceListApiApiResponseSchema
>;

const PostBasketMaterialItemPriceListApiBodySchema = z
  .object({
    warehouseArticleId: z.string(),
  })
  .nullable();

export const PostBasketMaterialItemPriceListApiApiArgSchema = z.object({
  packageId: z.string(),
  body: PostBasketMaterialItemPriceListApiBodySchema,
});

export type PostBasketMaterialItemPriceListApiApiArg = z.infer<
  typeof PostBasketMaterialItemPriceListApiApiArgSchema
>;

export const PostBasketMaterialItemWarehouseArticleApiApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
  })
  .nullable();

export type PostBasketMaterialItemWarehouseArticleApiApiResponse = z.infer<
  typeof PostBasketMaterialItemWarehouseArticleApiApiResponseSchema
>;

const PostBasketMaterialItemWarehouseArticleApiBodySchema = z
  .object({
    warehouseArticleId: z.string(),
  })
  .nullable();

export const PostBasketMaterialItemWarehouseArticleApiApiArgSchema = z.object({
  packageId: z.string(),
  body: PostBasketMaterialItemWarehouseArticleApiBodySchema,
});

export type PostBasketMaterialItemWarehouseArticleApiApiArg = z.infer<
  typeof PostBasketMaterialItemWarehouseArticleApiApiArgSchema
>;

export const MaterialBasketItemTypeSchema = z.object({
  basketItemId: z.string().optional(),
  warehouseId: z.string().optional(),
  warehouseArticleId: z.string().optional(),
  name: z.string().nullable().optional(),
  number: z.string().nullable().optional(),
  quantity: z.number().nullable().optional(),
  unit: z.string().nullable().optional(),
  dispensingUnit: z.number().nullable().optional(),
});

export type MaterialBasketItemType = z.infer<typeof MaterialBasketItemTypeSchema>;

export const GetBasketMaterialItemsApiApiResponseSchema = z
  .object({
    materialBasketItem: z.array(MaterialBasketItemTypeSchema).nullable().optional(),
  })
  .nullable();

export type GetBasketMaterialItemsApiApiResponse = z.infer<
  typeof GetBasketMaterialItemsApiApiResponseSchema
>;

export const GetBasketMaterialItemsApiApiArgSchema = z.object({
  packageId: z.string(),
});

export type GetBasketMaterialItemsApiApiArg = z.infer<typeof GetBasketMaterialItemsApiApiArgSchema>;
