import {Checkbox, Flag, IconButton, openDeleteDialog, Tooltip} from 'platform/components';
import {Box, Heading, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {Fragment} from 'react';

import {isNil, isNotNil, not} from 'ramda';

import {
  DOT_CHARACTER,
  EMPTY_PLACEHOLDER,
  getRandomId,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

import {CHECKBOX_TITLE_MIN_WIDTH} from '../../../constants/basket/checkboxTitleMinWidth';
import {HEADER_MIN_WIDTH} from '../../../constants/basket/headerMinWidth';
import {TITLE_MIN_WIDTH} from '../../../constants/basket/titleMinWidth';
import {TITLE_TOOLTIP_OPEN_DELAY} from '../../../constants/basket/titleTooltipOpenDelay';
import {JobChange} from '../../../types/basket/JobChange';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {MaterialBasketItem} from '../types/MaterialBasketItem';
import {MaterialBasketItemEditingDetails} from '../types/MaterialBasketItemEditingDetails';

interface AfterSalesMaterialBasketItemTitleWithActionsProps<T extends MaterialBasketItem>
  extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  // TODO: Remove optional in T20-62417
  onSelect?: (itemId: string, isSelected: boolean) => void;
  onEdit: (editingDetails: MaterialBasketItemEditingDetails) => void;
  onDelete: (itemsIds: string[]) => Promise<void>;
  jobChange?: JobChange;
}

export function AfterSalesMaterialBasketItemTitleWithActions<T extends MaterialBasketItem>(
  props: AfterSalesMaterialBasketItemTitleWithActionsProps<T>
) {
  const handleEditItem = () => {
    if (isNil(props.item.id)) {
      throw new Error("Missing item's id");
    }

    if (props.jobChange?.canPerformJobChange) {
      return props.onEdit({
        itemId: props.item.serviceOrderJobItemId as string,
        itemName: props.item.name,
        itemNumber: props.item?.number ?? props.item?.manufacturerNumber!,
        serviceCaseId: props.jobChange?.serviceCaseId,
        serviceOrderId: props.jobChange?.serviceOrderId,
        serviceJobId: props.item.serviceCaseJobId,
      });
    }

    props.onEdit({
      itemId: props.item.id,
      itemName: props.item.name,
      itemNumber: props.item?.number ?? props.item?.manufacturerNumber!,
    });
  };

  const handleDeleteItem = () => {
    openDeleteDialog({
      name: props.item.name,
      onConfirm: () => props.onDelete([props.item.id]),
      'data-testid': suffixTestId('delete', props),
    });
  };

  const tooltip = props.item.tooltip
    ? props.item.tooltip.map((item, index) => (
        <Fragment key={item.label}>
          {index > 0 && <Space vertical={2} />}
          <Text size="xSmall" alternative color="white">
            {item.label}
          </Text>
          <Text size="xSmall" color="white">
            {item.value || EMPTY_PLACEHOLDER}
          </Text>
        </Fragment>
      ))
    : null;

  return (
    <VStack spacing={1}>
      <HStack
        minWidth={HEADER_MIN_WIDTH}
        width="100%"
        justify="space-between"
        align="flex-start"
        spacing={2}
      >
        <HStack minWidth={CHECKBOX_TITLE_MIN_WIDTH} align="center" spacing={2}>
          {/* TODO: Remove optional in T20-62417 */}
          <Show when={isNotNil(props.item.isSelected)}>
            <Checkbox
              value={props.item.isSelected || false}
              onChange={(isSelected) => props.onSelect?.(props.item.id, isSelected)}
              data-testid={suffixTestId('isSelected', props)}
            />
          </Show>
          <Box minWidth={TITLE_MIN_WIDTH} width="100%">
            <Tooltip
              label={props.item?.number ?? props.item?.manufacturerNumber}
              placement="top"
              openDelay={TITLE_TOOLTIP_OPEN_DELAY}
              hasAutoWidth
            >
              <Heading size={4} isSingleLine data-testid={suffixTestId('number', props)}>
                {props.item?.number ?? props.item?.manufacturerNumber}
              </Heading>
            </Tooltip>
          </Box>
        </HStack>
        <HStack justify="flex-end" spacing={1}>
          {tooltip && (
            <Tooltip
              key={`basketItemTooltip-${getRandomId()}`}
              placement="top"
              description={tooltip}
            >
              <IconButton
                icon="action/info_outline"
                size="small"
                priority="secondary"
                data-testid={suffixTestId('tooltip', props)}
              />
            </Tooltip>
          )}
          <IconButton
            icon="image/edit"
            size="small"
            priority="secondary"
            isDisabled={not(props.item.itemEditingAllowed)}
            onClick={handleEditItem}
            data-testid={suffixTestId('edit', props)}
          />
          <IconButton
            icon="navigation/close"
            size="small"
            priority="primary"
            severity="danger"
            onClick={handleDeleteItem}
            data-testid={suffixTestId('delete', props)}
          />
        </HStack>
      </HStack>
      <HStack spacing={1} align="center">
        <Show when={isNotNil(props.item.requestType)}>
          <Flag
            label={props.item.requestType!}
            colorScheme="neutral"
            size="small"
            data-testid={suffixTestId('requestType', props)}
          />
          <Text size="xSmall" color="tertiary">
            {DOT_CHARACTER}
          </Text>
        </Show>
        <Text size="xSmall" color="tertiary" data-testid={suffixTestId('name', props)}>
          {props.item.name}
        </Text>
      </HStack>
      <Space vertical={2} />
    </VStack>
  );
}
