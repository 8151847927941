import {Button, Card} from 'platform/components';
import {Icon, Right, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useMemo} from 'react';
import {useDispatch} from 'react-redux';

import {isNil} from 'ramda';

import {salesPublishingApi} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  AdvertisingPlatformCodeEnum,
  changeCredentialsRequest,
  Form,
  PlatformInformation,
  Tag,
} from '@omnetic-dms/teas';

import {capitalize} from 'shared';

import {useBranchId} from '../../../../hooks/useBranchId';
import {remapPlatform} from '../../utils/remapPlatform';
import {PlatformAuthField} from './PlatformAuthField';

const PlatformTag = styled(Tag)`
  color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  margin-bottom: 20px;

  svg {
    margin-right: 9px;
  }
`;

export interface PlatformAuthenticationProps {
  platform?: PlatformInformation;
}

export const PlatformAuthentication = ({platform}: PlatformAuthenticationProps) => {
  const branchId = useBranchId();
  const dispatch = useDispatch();
  const {code} = platform as PlatformInformation;

  const saveCredentials = (credentials: Record<string, string>) => {
    dispatch(
      changeCredentialsRequest({
        platformCode: code as AdvertisingPlatformCodeEnum,
        credentials,
        branchId: branchId,
      })
    );
    dispatch(salesPublishingApi.util.invalidateTags(['PlatformList']));
  };

  const remappedPlatform = useMemo(
    () => remapPlatform(platform as PlatformInformation),
    [platform]
  );

  return (
    <>
      <Form
        onSubmit={saveCredentials}
        initialValues={remappedPlatform.credentials as any} // FIXME: !!
        isFormSaverDisabled
        render={(
          {handleSubmit}: any // FIXME: !!
        ) => (
          <Card
            title={`${i18n.t('general.labels.authentication')} – ${capitalize(code)}`}
            data-testid={testIds.settings.advertisingPlatforms(`credentials-${code}`)}
          >
            <VStack spacing={4}>
              <Text size="small" color="secondary">
                {i18n.t('page.settings.labels.authenticationDescription')}
              </Text>

              {!isNil(platform?.error) && (
                <PlatformTag
                  orange
                  data-testid={testIds.settings.advertisingPlatforms(`errorTag-${code}`)}
                >
                  <Icon value="alert/error_outline" color="severity.danger" />
                  {platform?.error.message ??
                    i18n.t('page.advertisingPlatformDetail.notifications.changeCredentialsError')}
                </PlatformTag>
              )}
              {!isNil(platform?.success) && (
                <PlatformTag
                  teal
                  data-testid={testIds.settings.advertisingPlatforms(`successTag-${code}`)}
                >
                  <Icon value="action/done" color="severity.success" />
                  {i18n.t('general.notifications.success')}
                </PlatformTag>
              )}

              <PlatformAuthField platform={remappedPlatform} />

              <Right>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  title={i18n.t('general.actions.save')}
                  data-testid={testIds.settings.advertisingPlatforms(`saveCredentials-${code}`)}
                />
              </Right>
            </VStack>
          </Card>
        )}
      />
    </>
  );
};
