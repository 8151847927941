import {Grid, Show} from 'platform/foundation';
import {css} from 'styled-components';

import {FC, PropsWithChildren} from 'react';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {getVolumeByDimensions} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {useGetVehicleEnums} from '../../../hooks/useGetVehicleEnums';
import {VehicleType} from '../../../types/VehicleType';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';

const loadingSpaceDimensionsNames = [
  'dimensions.dimensionsLoadingSpace.length',
  'dimensions.dimensionsLoadingSpace.width',
  'dimensions.dimensionsLoadingSpace.height',
] as const;
const loadingAreaName = 'dimensions.loadingArea';

export const Dimensions: FC<PropsWithChildren<any>> = () => {
  const {Field, Subscribe, form} = useFormRenderer<VehicleCreateFormState>();

  const {dimensions_height, dimensions_length} = useGetVehicleEnums(
    form.getFieldState('type')?.value
  );

  const dimensionsHeightOptions = Object.values(dimensions_height ?? {});
  const dimensionsLengthOptions = Object.values(dimensions_length ?? {});

  const setVolumeInput = (
    dimensionValue: number | Nullish,
    formName: (typeof loadingSpaceDimensionsNames)[number]
  ) => {
    if (isNil(dimensionValue)) {
      return;
    }
    const otherDimensionValues = loadingSpaceDimensionsNames.filter((item) => item !== formName);
    // @ts-ignore no point in typing this correctly because useFormRenderer has broken generic type
    const firstValue = form.getFieldState(otherDimensionValues[0])?.value;
    // @ts-ignore no point in typing this correctly because useFormRenderer has broken generic type
    const secondValue = form.getFieldState(otherDimensionValues[1])?.value;
    const volume = getVolumeByDimensions(
      Number(firstValue),
      Number(secondValue),
      Number(dimensionValue)
    );
    if (isNotNil(volume)) {
      const volumeInM3 = volume / 1_000_000_000;
      form.change(loadingAreaName, volumeInM3);
    }
  };

  return (
    <div
      css={css`
        margin-bottom: -16px;
      `}
    >
      <Subscribe<VehicleType>
        name="type"
        component={({input: {value: vehicleType}}) => (
          <Show when={vehicleType === 'VEHICLETYPE_VAN' || vehicleType === 'VEHICLETYPE_TRUCK'}>
            <Grid columns={4}>
              <Field
                as="select"
                name="dimensions.lengthCategory"
                label={i18n.t('entity.vehicle.labels.vehicleLength')}
                options={dimensionsLengthOptions}
              />
              <Field
                as="select"
                name="dimensions.heightCategory"
                label={i18n.t('entity.vehicle.labels.vehicleHeight')}
                options={dimensionsHeightOptions}
              />
            </Grid>
          </Show>
        )}
      />
      <Grid columns={4}>
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.dimensions.length"
          label={i18n.t('general.labels.length')}
          suffix={i18n.t('general.metric.mm')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.dimensions.width"
          label={i18n.t('general.labels.width')}
          suffix={i18n.t('general.metric.mm')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.dimensions.height"
          label={i18n.t('general.labels.height')}
          suffix={i18n.t('general.metric.mm')}
        />
      </Grid>
      <Subscribe<VehicleType>
        name="type"
        component={({input: {value: vehicleType}}) => (
          <Show when={vehicleType === 'VEHICLETYPE_VAN' || vehicleType === 'VEHICLETYPE_TRUCK'}>
            <Grid columns={4}>
              <Field
                as="number"
                name="dimensions.dimensionsLoadingSpace.length"
                label={i18n.t('entity.vehicle.labels.loadSpaceLength')}
                suffix={i18n.t('general.metric.mm')}
                onChange={(value) => {
                  setVolumeInput(value, 'dimensions.dimensionsLoadingSpace.length');
                }}
              />
              <Field
                as="number"
                name="dimensions.dimensionsLoadingSpace.width"
                label={i18n.t('entity.vehicle.labels.loadSpaceWidth')}
                suffix={i18n.t('general.metric.mm')}
                onChange={(value) => {
                  setVolumeInput(value, 'dimensions.dimensionsLoadingSpace.width');
                }}
              />
              <Field
                as="number"
                name="dimensions.dimensionsLoadingSpace.height"
                label={i18n.t('entity.vehicle.labels.loadSpaceHeight')}
                suffix={i18n.t('general.metric.mm')}
                onChange={(value) => {
                  setVolumeInput(value, 'dimensions.dimensionsLoadingSpace.height');
                }}
              />
              <Field
                as="number"
                name="dimensions.loadingArea"
                label={i18n.t('entity.vehicle.labels.loadArea')}
                suffix={i18n.t('general.metric.m3')}
              />
            </Grid>
          </Show>
        )}
      />
      <Grid columns={4}>
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.operatingWeight"
          label={i18n.t('entity.vehicle.labels.operatingWeight')}
          suffix={i18n.t('general.metric.kg')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.weight"
          label={i18n.t('entity.vehicle.labels.maxWeight')}
          suffix={i18n.t('general.metric.kg')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.maximalLoadCapacity"
          label={i18n.t('entity.vehicle.labels.maxLoadCapacity')}
          suffix={i18n.t('general.metric.kg')}
        />
      </Grid>
      <Grid columns={4}>
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.trunkSpace"
          label={i18n.t('entity.vehicle.labels.trunkSpace')}
          suffix={i18n.t('general.metric.l')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.wheelBase"
          label={i18n.t('entity.vehicle.labels.wheelBase')}
          suffix={i18n.t('general.metric.mm')}
        />
      </Grid>
    </div>
  );
};
