import {
  Box,
  Hide,
  HStack,
  Integer,
  Show,
  Space,
  TextProps,
  ThemeColorPath,
  VStack,
} from 'platform/foundation';
import styled, {useTheme} from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {ReactNode} from 'react';

import {always, any, not} from 'ramda';
import {isTruthy} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps, useToggle} from 'shared';

import {Action, Actions} from '../Actions/Actions';
import {FlagProps} from '../Flag/Flag';
import {IconButton} from '../IconButton/IconButton';
import {Parameter} from '../Parameters/types/Parameter';
import {CardControl, CardControlProps} from './components/CardControl';
import {CardHeadings} from './components/CardHeadings';
import {Collapse} from './components/Collapse';

export type CardVariantType = 'default' | 'inlineGrey' | 'inlineWhite' | 'defaultWithoutBorder';

interface BaseCardProps extends TestIdProps {
  children?: ReactNode | ReactNode[];
  variant?: CardVariantType | Nullish;
  title?: string | Nullish;
  subtitle?: string | Nullish;
  parameters?: Parameter[];
  parametersProps?: TextProps;
  actions?: Action[];
  control?: CardControlProps;
  flags?: FlagProps[];
  tooltip?: string | Nullish;
}

interface ExpandableCardProps extends BaseCardProps {
  isFullHeight?: never;
  isExpanded?: boolean;
  isExpandable?: boolean | Nullish;
  isClosedByDefault?: boolean | Nullish;
  onExpandButtonClick?: VoidFunction;
}

interface FullHeightCardProps extends BaseCardProps {
  isFullHeight?: boolean;
  isExpandable?: never;
  isExpanded?: never;
  isClosedByDefault?: never;
  onExpandButtonClick?: never;
}

export type CardProps = ExpandableCardProps | FullHeightCardProps;

export function Card(props: CardProps) {
  const [isInternallyExpanded, toggleIsInternallyExpanded] = useToggle(!props.isClosedByDefault);

  const theme = useTheme();

  const isExpanded = props.isExpanded ?? isInternallyExpanded;
  const isInline = 'inlineWhite' === props.variant || 'inlineGrey' === props.variant;
  const isDefaultWithoutBorder = 'defaultWithoutBorder' === props.variant;
  const isExpandableByHeader = props.isExpandable && not(isInline);

  const wrapperBorderColor = match<[boolean, boolean, boolean], ThemeColorPath>([
    isInline,
    isDefaultWithoutBorder,
    isExpanded,
  ])
    .with([false, false, true], always('palettes.neutral.40.100'))
    .otherwise(always('general.transparent'));

  const hasHeader = any(isTruthy, [
    props.title,
    props.subtitle,
    props.flags,
    props.parameters?.length,
    props.actions?.length,
    props.control,
    props.tooltip,
  ]);

  const bodyPadding = match<[boolean, boolean, boolean], Integer>([
    isInline,
    isDefaultWithoutBorder,
    hasHeader,
  ])
    .with([true, Pattern.boolean, true], [Pattern.boolean, true, true], always(0))
    .otherwise(always(4));

  const onExpandButtonClick = () => props.onExpandButtonClick?.() ?? toggleIsInternallyExpanded();

  const body = (
    <Box
      flex={1}
      paddingBottom={4}
      paddingHorizontal={4}
      paddingTop={bodyPadding}
      position="relative"
      overflow={props.isFullHeight ? 'auto' : undefined}
    >
      {props.children}
    </Box>
  );

  return (
    <Box
      height={props.isFullHeight ? '100%' : undefined}
      data-testid={suffixTestId('cardWrapper', props)}
    >
      <Box
        height="100%"
        borderRadius={theme.components.Card.borderRadius}
        boxShadow={not(isInline) ? theme.components.Card.elevation : undefined}
        borderColor={isInline ? 'palettes.neutral.40.100' : undefined}
        border={isInline ? '1px solid' : undefined}
        backgroundColor={
          props.variant === 'inlineGrey' ? 'palettes.neutral.10.100' : 'palettes.white.10.100'
        }
      >
        <VStack height="100%">
          <Show when={hasHeader}>
            <Box
              overflow="hidden"
              paddingHorizontal={4}
              borderBottom="1px solid"
              borderColor={wrapperBorderColor}
              transition="border-color ease-in-out 150ms"
            >
              <HStack minHeight={14} spacing={3} align="center" justify="space-between">
                <Show when={props.control}>
                  <CardControl
                    {...props.control!}
                    data-testid={suffixTestId('cardControl', props)}
                  />
                </Show>

                <CardHeadings
                  title={props.title}
                  subtitle={props.subtitle}
                  parameters={props.parameters}
                  flags={props.flags}
                  tooltip={props.tooltip}
                  data-testid={props['data-testid']}
                  parametersProps={props.parametersProps}
                />

                <Show when={isExpandableByHeader}>
                  <ClickableZone onClick={onExpandButtonClick} />
                </Show>

                <Hide when={isExpandableByHeader}>
                  <Space fillAvailable />
                </Hide>

                <Actions
                  actions={props.actions}
                  data-testid={suffixTestId('cardHeaderActions', props)}
                />

                <Show when={props.isExpandable}>
                  <IconButton
                    size="small"
                    priority="default"
                    onClick={onExpandButtonClick}
                    icon={isExpanded ? 'navigation/expand_less' : 'navigation/expand_more'}
                    data-testid={suffixTestId('cardHeaderExpandButton', props)}
                  />
                </Show>
              </HStack>
            </Box>
          </Show>
          {props.isFullHeight ? body : <Collapse isOpen={isExpanded}>{body}</Collapse>}
        </VStack>
      </Box>
    </Box>
  );
}

const ClickableZone = styled.div`
  flex: 1;
  cursor: pointer;
  align-self: stretch;
`;
