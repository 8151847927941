import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

export const HeaderDynamicActionsResourcesSchema = z.union([
  z.literal('delivery-note'),
  z.literal('direct-sale'),
  z.literal('credit-note'),
  z.literal('service-order-issue-note'),
  z.literal('parts-request-return'),
  z.literal('service-order-return'),
]);
export type HeaderDynamicActionsResources = z.infer<typeof HeaderDynamicActionsResourcesSchema>;

export const HeaderDynamicActionsOptionsSchema = z.array(
  z.object({
    key: z.string(),
    style: z.string(),
    position: z.number(),
    title: z.string(),
    variant: z.string(),
  })
);

export type HeaderDynamicActionsOptions = z.infer<typeof HeaderDynamicActionsOptionsSchema>;

export const HeaderDynamicActionsResponseSchema = z.object({
  actions: z.object({
    optionClick: z.string(),
    options: HeaderDynamicActionsOptionsSchema,
  }),
});
export type HeaderDynamicActionsResponse = z.infer<typeof HeaderDynamicActionsResponseSchema>;

export const HeaderDynamicActionsRequestSchema = z.object({
  resource: HeaderDynamicActionsResourcesSchema,
  id: z.string(),
});
export type HeaderDynamicActionsRequest = z.infer<typeof HeaderDynamicActionsRequestSchema>;

export const HeaderDynamicActionsEventRequestSchema = z.object({
  id: z.string(),
  resource: HeaderDynamicActionsResourcesSchema,
  body: z.object({
    actionKey: z.string(),
    returnedBy: z.string().optional(),
    originEntityHeaderType: z
      .enum([
        'service-case-order',
        'direct-sale',
        'service-order-issue-note',
        'service-order-return',
      ])
      .optional(),
    newDirectSaleVariantId: z.string().optional(),
  }),
});

export type HeaderDynamicActionsEventRequest = z.infer<
  typeof HeaderDynamicActionsEventRequestSchema
>;

export const HeaderDynamicActionsEventResponseSchema = BaseVoidResponseSchema;
export type HeaderDynamicActionsEventResponse = z.infer<
  typeof HeaderDynamicActionsEventResponseSchema
>;
