import {Alert, Card, closeCurrentDialog, DataStatus, openDialog} from 'platform/components';
import {Scroll, Show, VStack} from 'platform/foundation';

import {useMemo} from 'react';

import {equals, not} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  MaterialBasketItemType,
  useDeleteBasketItemApiMutation,
  useGetBasketMaterialItemsApiQuery,
  usePatchBasketItemQuantityApiMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  AfterSalesMaterialBasketItem,
  EitherQuantityOrError,
  handleApiError,
  useInvalidBasketItemsIds,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {EditMaterialItem} from './EditMaterialItem';

interface MaterialBasketCardProps extends TestIdProps {
  packageId: string;
}

export function MaterialBasketCard(props: MaterialBasketCardProps) {
  const {data, isLoading, isError} = useGetBasketMaterialItemsApiQuery({
    packageId: props.packageId,
  });

  const basketItems = useMemo(
    () =>
      data?.materialBasketItem?.map((basketItem) => ({
        ...basketItem,
        id: basketItem.basketItemId ?? '',
      })) || [],
    [data]
  );

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);
  const [patchBasketItemQuantity] = usePatchBasketItemQuantityApiMutation();
  const [deleteBasketItem] = useDeleteBasketItemApiMutation();

  const handleEditItem = (item: MaterialBasketItemType) =>
    openDialog(
      <EditMaterialItem
        itemId={item.basketItemId ?? ''}
        context="basket"
        onClose={closeCurrentDialog}
      />,
      {
        title: `${item.name} (${item.number})`,
      }
    );

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await patchBasketItemQuantity({
      basketItemId: itemId,
      body: {
        quantity: quantity.newQuantity || 0,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleDeleteWorkItem = (itemsIds: string[]) => {
    const itemId = itemsIds[0];
    return deleteBasketItem({basketItemId: itemId}).unwrap().catch(handleApiError);
  };

  const count = basketItems?.length ?? 0;

  return (
    <Card
      isFullHeight
      title={`${i18n.t('general.labels.selected')} (${count})`}
      data-testid={suffixTestId('header', props)}
    >
      <VStack height="100%">
        <Scroll flex={1} auto>
          <VStack height="100%" spacing={4}>
            <Show when={isNotNilOrEmpty(invalidBasketItemsIds)}>
              <Alert
                type="inline"
                variant="error"
                title={i18n.t('general.notifications.basketHasInvalidItems')}
                data-testid={suffixTestId('alert.hasInvalidItems', props)}
              />
            </Show>
            <DataStatus
              isLoading={isLoading}
              isError={isError}
              minHeight="100%"
              isEmpty={count === 0}
            >
              {basketItems.map((item) => (
                <AfterSalesMaterialBasketItem
                  key={item.basketItemId}
                  item={{
                    id: item.basketItemId ?? '',
                    name: item.name ?? '',
                    quantity: item.quantity ?? 0,
                    number: item.number ?? '',
                    unit: item.unit ?? '',
                    dispensingUnit: item.dispensingUnit ?? 0,
                    itemEditingAllowed: true,
                    quantityEditingAllowed: true,
                  }}
                  onQuantityChange={handleQuantityChange}
                  onEdit={() => handleEditItem(item)}
                  onDelete={handleDeleteWorkItem}
                  data-testid={suffixTestId(`basketItem`, props)}
                />
              ))}
            </DataStatus>
          </VStack>
        </Scroll>
      </VStack>
    </Card>
  );
}
