import {Checkbox, IconButton, openDeleteDialog, Tooltip} from 'platform/components';
import {Box, Heading, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {Fragment} from 'react';

import {isNil, isNotNil, not} from 'ramda';

import {EMPTY_PLACEHOLDER, getRandomId, RequiredTestIdProps, suffixTestId} from 'shared';

import {CHECKBOX_TITLE_MIN_WIDTH} from '../../../constants/basket/checkboxTitleMinWidth';
import {HEADER_MIN_WIDTH} from '../../../constants/basket/headerMinWidth';
import {TITLE_MIN_WIDTH} from '../../../constants/basket/titleMinWidth';
import {TITLE_TOOLTIP_OPEN_DELAY} from '../../../constants/basket/titleTooltipOpenDelay';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {CorrectionBasketItem} from '../types/CorrectionBasketItem';
import {CorrectionBasketItemEditingDetails} from '../types/CorrectionBasketItemEditingDetails';

interface AfterSalesCorrectionBasketItemTitleWithActionsProps<T extends CorrectionBasketItem>
  extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  onSelect: (itemId: string, isSelected: boolean) => void;
  onEdit: (editingDetails: CorrectionBasketItemEditingDetails) => void;
  onDelete: (itemsIds: string[]) => Promise<void>;
}

export function AfterSalesCorrectionBasketItemTitleWithActions<T extends CorrectionBasketItem>(
  props: AfterSalesCorrectionBasketItemTitleWithActionsProps<T>
) {
  const handleEditItem = () => {
    if (isNil(props.item.id)) {
      throw new Error("Missing item's id");
    }

    props.onEdit({
      itemId: props.item.id,
      itemName: props.item.name,
    });
  };

  const handleDeleteItem = () => {
    openDeleteDialog({
      name: props.item.name,
      onConfirm: () => props.onDelete([props.item.id]),
      'data-testid': suffixTestId('delete', props),
    });
  };

  const tooltip = props.item.tooltip
    ? props.item.tooltip.map((item, index) => (
        <Fragment key={item.label}>
          {index > 0 && <Space vertical={2} />}
          <Text size="xSmall" alternative color="white">
            {item.label}
          </Text>
          <Text size="xSmall" color="white">
            {item.value || EMPTY_PLACEHOLDER}
          </Text>
        </Fragment>
      ))
    : null;

  return (
    <VStack spacing={1}>
      <HStack
        minWidth={HEADER_MIN_WIDTH}
        width="100%"
        justify="space-between"
        align="flex-start"
        spacing={2}
      >
        <HStack minWidth={CHECKBOX_TITLE_MIN_WIDTH} align="center" spacing={2}>
          {/* TODO: Remove optional in T20-62417 */}
          <Show when={isNotNil(props.item.isSelected)}>
            <Checkbox
              value={props.item.isSelected || false}
              onChange={(isSelected) => props.onSelect(props.item.id, isSelected)}
              data-testid={suffixTestId('isSelected', props)}
            />
          </Show>
          <Box minWidth={TITLE_MIN_WIDTH} width="100%">
            <Tooltip
              label={props.item?.number ?? props.item?.manufacturerNumber}
              placement="top"
              openDelay={TITLE_TOOLTIP_OPEN_DELAY}
              hasAutoWidth
            >
              <Heading size={4} isSingleLine data-testid={suffixTestId('number', props)}>
                {props.item?.number ?? props.item?.manufacturerNumber}
              </Heading>
            </Tooltip>
          </Box>
        </HStack>
        <HStack justify="flex-end" spacing={1}>
          {tooltip && (
            <Tooltip
              key={`basketItemTooltip-${getRandomId()}`}
              placement="top"
              description={tooltip}
            >
              <IconButton
                icon="action/info_outline"
                size="small"
                priority="secondary"
                data-testid={suffixTestId('tooltip', props)}
              />
            </Tooltip>
          )}
          <IconButton
            icon="image/edit"
            size="small"
            priority="secondary"
            isDisabled={not(props.item.itemEditingAllowed)}
            onClick={handleEditItem}
            data-testid={suffixTestId('edit', props)}
          />
          <IconButton
            icon="navigation/close"
            size="small"
            priority="primary"
            severity="danger"
            onClick={handleDeleteItem}
            data-testid={suffixTestId('delete', props)}
          />
        </HStack>
      </HStack>
      <Text size="xSmall" color="tertiary" data-testid={suffixTestId('name', props)}>
        {props.item.name}
      </Text>
      <Space vertical={2} />
    </VStack>
  );
}
