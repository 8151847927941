import {DataStatus} from 'platform/components';
import {Show} from 'platform/foundation';

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {isNil} from 'ramda';

import {featureFlags} from '@omnetic-dms/feature-flags';
import {fakePlatforms} from '@omnetic-dms/shared';
import {
  AdvertisingPlatformCodeEnum,
  fetchSpecificPlatformRequest,
  specificPlatformSelector,
} from '@omnetic-dms/teas';

import {useBranchId} from '../../../hooks/useBranchId';
import {PlatformAuthentication} from './components/PlatformAuthentication';
import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';
import {PlatformTipCarsInfo} from './components/PlatformTipCarsInfo';
import {PlatformTipCarsVin} from './components/PlatformTipCarsVin';

interface PlatformSettingsProps {
  code: string;
}

// TODO T20-64403 refactor to RTK Query and make this component for Tipcars only
export function PlatformSettings(props: PlatformSettingsProps) {
  const branchId = useBranchId();
  const dispatch = useDispatch();
  const platform = useSelector(specificPlatformSelector(props.code as AdvertisingPlatformCodeEnum));

  useEffect(() => {
    if (props.code) {
      dispatch(
        fetchSpecificPlatformRequest({platformCode: props.code, branchId: branchId ?? undefined})
      );
    }
  }, [dispatch, props.code, branchId]);

  // Show nothing in case of fake platform
  if (fakePlatforms.some((platform) => platform.code === props.code)) {
    return null;
  }

  return (
    <>
      <DataStatus isLoading={isNil(platform)}>
        <PlatformAuthentication platform={platform} />
      </DataStatus>
      <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
        <PlatformPhotoSettings platformCode={props.code} />
      </Show>
      <Show when={props.code === AdvertisingPlatformCodeEnum.TIPCARS}>
        <PlatformTipCarsVin />
        <PlatformTipCarsInfo platform={platform} />
      </Show>
    </>
  );
}
