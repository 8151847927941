import {VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useParams} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {composePath} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {PlatformSettings} from './platforms/PlatformSettings';
import {PlatformSettingsAutocaris} from './platforms/PlatformSettingsAutocaris';
import {PlatformSettingsAutoplacPl} from './platforms/PlatformSettingsAutoplacPl';
import {PlatformSettingsDasWeltAuto} from './platforms/PlatformSettingsDasWeltAuto';
import {PlatformSettingsFacebook} from './platforms/PlatformSettingsFacebook';
import {PlatformSettingsFordGeronimo} from './platforms/PlatformSettingsFordGeronimo';
import {PlatformSettingsFordUzywane} from './platforms/PlatformSettingsFordUzywane';
import {PlatformSettingsHyundaiPromise} from './platforms/PlatformSettingsHyundaiPromise';
import {PlatformSettingsMamGo} from './platforms/PlatformSettingsMamGo';
import {PlatformSettingsMercedesNaSklade} from './platforms/PlatformSettingsMercedesNaSklade';
import {PlatformSettingsMobileDe} from './platforms/PlatformSettingsMobileDe';
import {PlatformSettingsMyWeb} from './platforms/PlatformSettingsMyWeb';
import {PlatformSettingsOlx} from './platforms/PlatformSettingsOlx';
import {PlatformSettingsOtomoto} from './platforms/PlatformSettingsOtomoto';
import {PlatformSettingsSauto} from './platforms/PlatformSettingsSauto';
import {PlatformSettingsSkodaPlus} from './platforms/PlatformSettingsSkodaPlus';
import {PlatformSettingsVehis} from './platforms/PlatformSettingsVehis';

export function AdvertisingPlatformDetail() {
  const {code} = useParams();

  const HEADER: SettingsTemplateHeader = {
    breadcrumbs: [
      {
        label: i18n.t('page.salesSettings.labels.advertising'),
        href: settingsRoutes.advertising,
      },
      {
        label: i18n.t('page.settings.labels.advertisementPlatform'),
        href: composePath(settingsRoutes.advertisingPlatforms, {}),
      },
    ],
  };

  return (
    <SettingsTemplate header={HEADER} data-testid="settings-advertising-platform-detail">
      <VStack spacing={4}>
        {match(code)
          .with(AdvertisingPlatformCodeEnum.SAUTO, () => <PlatformSettingsSauto />)
          .with(AdvertisingPlatformCodeEnum.MOBILE_DE, () => <PlatformSettingsMobileDe />)
          .with(AdvertisingPlatformCodeEnum.MYWEB, () => <PlatformSettingsMyWeb />)
          .with(AdvertisingPlatformCodeEnum.FACEBOOK, () => <PlatformSettingsFacebook />)
          .with(AdvertisingPlatformCodeEnum.AUTOCARIS, () => <PlatformSettingsAutocaris />)
          .with(AdvertisingPlatformCodeEnum.OTOMOTO, () => <PlatformSettingsOtomoto />)
          .with(AdvertisingPlatformCodeEnum.OLX, () => <PlatformSettingsOlx />)
          .with(AdvertisingPlatformCodeEnum.SKODA_PLUS, () => <PlatformSettingsSkodaPlus />)
          .with(AdvertisingPlatformCodeEnum.DAS_WELT_AUTO, () => <PlatformSettingsDasWeltAuto />)
          .with(AdvertisingPlatformCodeEnum.MERCEDES_NA_SKLADE, () => (
            <PlatformSettingsMercedesNaSklade />
          ))
          .with(AdvertisingPlatformCodeEnum.HYUNDAI_PROMISE, () => (
            <PlatformSettingsHyundaiPromise />
          ))
          .with(AdvertisingPlatformCodeEnum.FORD_UZYWANE, () => <PlatformSettingsFordUzywane />)
          .with(AdvertisingPlatformCodeEnum.AUTOPLAC_PL, () => <PlatformSettingsAutoplacPl />)
          .with(AdvertisingPlatformCodeEnum.VEHIS, () => <PlatformSettingsVehis />)
          .with(AdvertisingPlatformCodeEnum.MAMGO, () => <PlatformSettingsMamGo />)
          .with(AdvertisingPlatformCodeEnum.FORD_GERONIMO, () => <PlatformSettingsFordGeronimo />)
          .otherwise(
            /* Tipcars or fake platforms */
            () => code && <PlatformSettings code={code} />
          )}
      </VStack>
    </SettingsTemplate>
  );
}
