import {NumberInput} from 'platform/components';
import {Box, HStack, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {isNotNil, not} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {yupNumber, RequiredTestIdProps, suffixTestId} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {useBasketItemQuantity} from '../../../hooks/useBasketItemQuantity';
import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {LabourBasketItem} from '../types/LabourBasketItem';

interface AfterSalesLabourBasketItemQuantityWithPriceProps<T extends LabourBasketItem>
  extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
}

export function AfterSalesLabourBasketItemQuantityWithPrice<T extends LabourBasketItem>(
  props: AfterSalesLabourBasketItemQuantityWithPriceProps<T>
) {
  const formatCurrency = useFormatCurrency();

  const {quantity, quantityError, handleQuantityChange} = useBasketItemQuantity({
    itemId: props.item.id,
    currentQuantity: props.item.quantity,
    validationSchema: quantitySchema,
    onQuantityChange: props.onQuantityChange,
  });

  // TODO: Remove optional in T20-62417
  const totalPrice = formatCurrency(
    props.item.totalPrice?.withoutVat?.amount ?? 0,
    props.item.totalPrice?.withoutVat?.currency ?? DEFAULT_CURRENCY,
    2
  );

  return (
    <HStack justify="space-between">
      <Box width={29}>
        <NumberInput
          value={quantity}
          errorMessage={quantityError}
          onChange={handleQuantityChange}
          isStepperVisible
          isInvalid={isNotNil(quantityError)}
          isDisabled={not(props.item.quantityEditingAllowed)}
          data-testid={suffixTestId('quantity', props)}
        />
      </Box>
      <VStack align="flex-end">
        <Text size="xSmall" color="tertiary">
          {i18n.t('general.labels.sellingPrice')}
        </Text>
        <Text size="small" alternative data-testid={suffixTestId('price', props)}>
          {totalPrice}
        </Text>
      </VStack>
    </HStack>
  );
}

const quantitySchema = yupNumber.positive();
