import {OmneticKeys} from 'i18next';
import {FormControl, FormField} from 'platform/components';
import {Box, Heading, HStack, Space, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {isNilOrEmpty} from 'ramda-adjunct';

import {SeriesType, useGetSeriesListQuery, useGetTemplatesQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../../components/SettingsSection/SettingsSection';
import {FormType} from '../../types/ServiceOrderVariant';

interface DocumentsTabProps {
  control: FormControl<FormType>;
  formApi: UseFormReturn<FormType>;
}

export function DocumentsTab(props: DocumentsTabProps) {
  const navigate = useNavigate();

  //TODO: Add claim protocol options when implemented on BE
  const {data: templates, isLoading: isTemplatesLoading} = useGetTemplatesQuery({});
  const {data: series, isLoading: isSeriesLoading} = useGetSeriesListQuery({
    type: [
      'workshop/service_order_list',
      'workshop/service_order_offer',
      'workshop/service_order_parts_return',
    ],
  });

  const getTemplateOptions = (
    documentKindCode:
      | 'service-case-order-offer'
      | 'service-case-order-sheet'
      | 'invoice'
      | 'service-order-return'
  ) =>
    templates
      ?.filter((template) => template?.documentKindCode === documentKindCode)
      ?.map((template) => ({
        label: template.title,
        value: template.id,
      }));

  const getSeriesOptions = (type: SeriesType) =>
    series
      ?.filter((series) => series.type === type)
      .map((series) => ({
        label: series.name,
        value: series.id,
      }));

  return (
    <SettingsSection>
      <VStack spacing={4}>
        <Heading size={4}>{i18n.t('general.labels.documents')}</Heading>
        <HStack spacing={4}>
          <Box flex={1}>
            <FormField
              control={props.control}
              name="documents.orderSheet.templateId"
              type="choice"
              label={getTemplateLabel('entity.order.actions.orderList')}
              options={getTemplateOptions('service-case-order-sheet')}
              isLoading={isTemplatesLoading}
              data-testid={testIds.settings.serviceOrderVariantsDetail('orderListTemplate')}
            />
          </Box>
          <Box flex={1}>
            <FormField
              control={props.control}
              name="documents.orderSheet.docSeriesId"
              type="choice"
              label={getSeriesLabel('entity.order.actions.orderList')}
              options={getSeriesOptions('workshop/service_order_list')}
              isLoading={isSeriesLoading}
              isDisabled={isNilOrEmpty(props.formApi.watch('documents.orderSheet.templateId'))}
              data-testid={testIds.settings.serviceOrderVariantsDetail('orderListSeries')}
            />
          </Box>
        </HStack>
        <HStack spacing={4}>
          <Box flex={1}>
            <FormField
              control={props.control}
              name="documents.orderOffer.templateId"
              type="choice"
              label={getTemplateLabel('entity.interest.labels.offer')}
              options={getTemplateOptions('service-case-order-offer')}
              isLoading={isTemplatesLoading}
              data-testid={testIds.settings.serviceOrderVariantsDetail('offerTemplate')}
            />
          </Box>
          <Box flex={1}>
            <FormField
              control={props.control}
              name="documents.orderOffer.docSeriesId"
              type="choice"
              label={getSeriesLabel('entity.interest.labels.offer')}
              options={getSeriesOptions('workshop/service_order_offer')}
              isLoading={isSeriesLoading}
              isDisabled={isNilOrEmpty(props.formApi.watch('documents.orderOffer.templateId'))}
              data-testid={testIds.settings.serviceOrderVariantsDetail('offerSeries')}
            />
          </Box>
        </HStack>
        <HStack spacing={4}>
          <Box flex={1}>
            <FormField
              control={props.control}
              name="documents.orderClaim.templateId"
              type="choice"
              label={getTemplateLabel('entity.order.labels.claimProtocol')}
              data-testid={testIds.settings.serviceOrderVariantsDetail('claimTemplate')}
            />
          </Box>
          <Box flex={1}>
            <FormField
              control={props.control}
              name="documents.orderClaim.docSeriesId"
              type="choice"
              label={getSeriesLabel('entity.order.labels.claimProtocol')}
              isDisabled={isNilOrEmpty(props.formApi.watch('documents.orderClaim.templateId'))}
              data-testid={testIds.settings.serviceOrderVariantsDetail('claimSeries')}
            />
          </Box>
        </HStack>
        <HStack spacing={4}>
          <Box flex={1}>
            <FormField
              control={props.control}
              name="documents.serviceOrderReturn.templateId"
              type="choice"
              label={getTemplateLabel('entity.order.labels.serviceOrderReturn')}
              // TODO: Remove isDisabled and connect properly once finished
              // https://carvago.atlassian.net/browse/T20-63995
              isDisabled
              options={getTemplateOptions('service-order-return')}
              data-testid={testIds.settings.serviceOrderVariantsDetail(
                'serviceOrderReturnTemplate'
              )}
            />
          </Box>
          <Box flex={1}>
            <FormField
              control={props.control}
              name="documents.serviceOrderReturn.docSeriesId"
              type="choice"
              label={getSeriesLabel('entity.order.labels.serviceOrderReturn')}
              options={getSeriesOptions('workshop/service_order_parts_return')}
              // TODO: Make disabled once finished
              // https://carvago.atlassian.net/browse/T20-63995
              // isDisabled={isNilOrEmpty(
              //   props.formApi.watch('documents.serviceOrderReturn.templateId')
              // )}
              data-testid={testIds.settings.serviceOrderVariantsDetail('serviceOrderReturnSeries')}
            />
          </Box>
        </HStack>
      </VStack>
      <Space vertical={18} />
      <SettingsFooter
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('general.actions.discardChanges'),
            onClick: () => navigate(settingsRoutes.serviceOrderVariants),
          },
          {
            type: 'form-button',
            control: props.control,
            buttonType: 'submit',
            variant: 'primary',
            title: i18n.t('general.actions.saveChanges'),
          },
        ]}
        data-testid={testIds.settings.serviceOrderVariantsDetail('footer')}
      />
    </SettingsSection>
  );
}

const getTemplateLabel = (key: OmneticKeys) =>
  `${i18n.t(key)} (${i18n.t('general.labels.template').toLocaleLowerCase()})`;

const getSeriesLabel = (key: OmneticKeys) =>
  `${i18n.t(key)} (${i18n.t('entity.document.labels.documentSerie').toLocaleLowerCase()})`;
